// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/SideBar";
import LifeCalendar from "./pages/LifeCalendar";
import Quotes from "./pages/Quotes";

function App() {
  return (
    <Router>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1 p-3">
          <Routes>
            <Route path="/" element={<LifeCalendar />} />
            <Route path="/quotes" element={<Quotes />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
