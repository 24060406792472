import React, { useState, useEffect } from "react";
import "./Quotes.css"; // Import the CSS file

const Quote = () => {
  const [quotes, setQuotes] = useState([]); // State to hold the quotes
  const [currentIndex, setCurrentIndex] = useState(0); // State to hold the current quote index
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null); // State to track errors

  // Fetch quotes from the API
  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await fetch(
          "https://momentomori.onrender.com/v1/get_all_quotes/?shuffle=true"
        ); // Fetch quotes from your API endpoint
        const data = await response.json();

        if (data.status === "SUCCESS") {
          setQuotes(data.data); // Set quotes state with the fetched data
        } else {
          setError("Failed to fetch quotes");
        }
      } catch (err) {
        setError("An error occurred while fetching quotes");
        console.error("Error fetching quotes:", err);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchQuotes();
  }, []); // Empty dependency array means this runs once when the component mounts

  const nextQuote = () => {
    setCurrentIndex((prev) => (prev + 1) % quotes.length); // Move to the next quote
  };

  const previousQuote = () => {
    setCurrentIndex((prev) => (prev - 1 + quotes.length) % quotes.length); // Move to the previous quote
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading message while fetching
  }

  if (error) {
    return <div>{error}</div>; // Show error message if any
  }

  return (
    <div className="quote-container">
      <div className="quote-card">
        <div className="quote-content">
          <div className="avatar">
            <img
              src={quotes[currentIndex].avatar}
              alt="Avatar"
              className="avatar-img"
            />
          </div>

          <p className="quote-text">{quotes[currentIndex].text}</p>

          <p className="quote-author">
            — {quotes[currentIndex].author} in {quotes[currentIndex].source}
          </p>
        </div>

        <button
          onClick={previousQuote}
          className="quote-nav-button prev-button"
          aria-label="Previous quote"
        >
          ←
        </button>

        <button
          onClick={nextQuote}
          className="quote-nav-button next-button"
          aria-label="Next quote"
        >
          →
        </button>
      </div>

      <div className="pagination">
        {quotes.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`pagination-dot ${
              index === currentIndex ? "active" : ""
            }`}
            aria-label={`Go to quote ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Quote;
