// Sidebar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./SideBar.css";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`d-flex flex-column align-items-${
        isOpen ? "start" : "center"
      } sidebar bg-white`}
    >
      <button
        className="btn btn-link text-decoration-none text-primary"
        onClick={toggleSidebar}
      >
        <i className={`bi ${isOpen ? "bi-x-circle" : "bi-chevron-right"}`} />
      </button>

      <ul
        className={`nav flex-column ${isOpen ? "text-start" : "text-center"}`}
      >
        <li className="nav-item">
          <Link to="/" className="nav-link text-primary">
            <i className="bi bi-calendar3" /> {isOpen && "Life Calendar"}
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/quotes" className="nav-link text-primary">
            <i className="bi bi-chat-quote" /> {isOpen && "Quotes"}
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
