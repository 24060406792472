import React, { useEffect, useState } from "react";
import "./LifeCalendar.css";
const circlesPerRow = 52;
const totalRows = 80;

function LifeCalendar() {
  const [birthday, setBirthday] = useState("");
  const [aliveWeeks, setAliveWeeks] = useState(0);
  const [remainingWeeks, setRemaningWeeks] = useState(0);

  const fetchData = (userBirthday) => {
    fetch(
      `https://momentomori.onrender.com/v1/calculate/?birthday=${userBirthday}&expected_death_age=80`
    )
      .then((response) => response.json())
      .then((data) => {
        setAliveWeeks(data.data.weeks_alive || 0);
        setRemaningWeeks(data.data.remaining_weeks || 0);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (birthday) {
      fetchData(birthday);
    }
  };

  useEffect(() => {
    drawCanvas(aliveWeeks);
  }, [aliveWeeks]);

  function drawCanvas(filledCircles) {
    const canvas = document.getElementById("circleCanvas");
    const ctx = canvas.getContext("2d");

    const circleSize = 19;
    const margin = 1;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (let row = 0; row < totalRows; row++) {
      for (let col = 0; col < circlesPerRow; col++) {
        const circleIndex = row * circlesPerRow + col;
        const isFilled = circleIndex < filledCircles;

        const x = col * (circleSize + margin);
        const y = row * (circleSize + margin);

        ctx.beginPath();
        ctx.arc(
          x + circleSize / 2,
          y + circleSize / 2,
          circleSize / 2,
          0,
          2 * Math.PI
        );
        ctx.fillStyle = isFilled ? "#880808" : "#6c6c6c";
        ctx.strokeStyle = "black";
        ctx.lineWidth = 1;

        if (!isFilled) {
          ctx.stroke();
        }

        ctx.fill();
        ctx.closePath();
      }
    }
  }

  return (
    <div className="content">
      <div className="left-section">
        <h1 className="title">MOMENTO MORI</h1>
        <form onSubmit={handleSubmit} className="birthday-form">
          <label className="label">
            Enter your birthday
            <br />
            <span className="subtext">(MM-DD-YYYY):</span>
            <br />
            <input
              type="date"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
              required
              className="birthday-input"
            />
          </label>
          <br />
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
        <div className="stats">
          <p className="stat">
            WEEKS ALIVE: <span>{aliveWeeks}</span>
          </p>
          <p className="stat">
            REMAINING WEEKS: <span>{remainingWeeks}</span>
          </p>
        </div>
      </div>
      <div className="canvas-container">
        <canvas
          id="circleCanvas"
          width={circlesPerRow * 22}
          height={totalRows * 22}
          className="circle-canvas"
        ></canvas>
      </div>
    </div>
  );
}

export default LifeCalendar;
